<template>
  <div style="padding-top:60px">
    <div class="banner">
      <div class="banner-btn"
           @click="LiJiTiyan"></div>
    </div>
    <div>
      <div class="center-box">
        <div class="content-title">
          <div>公司介绍</div>
        </div>
        <div class="gs-js">
          <div>
            <img src="../assets/images/pc/jieshao.png"
                 alt="">
          </div>
          <div class="gsjs-list"
               v-for="(item,index) in gsDescList"
               :key="index">
            <div>{{item.title}}</div>
            <div>{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="ls-center-box">
        <div class="content-title">
          <div>联系我们</div>
        </div>
        <div class="lx-box">
          <!-- 在线客服 -->
          <div class="lx-list lx-kefu">
            <div>在线客服</div>
            <div>首选推荐：9:00-23:00</div>
            <div>
              <img src="../assets/images/pc/wxcode.jpg"
                   alt="">
            </div>
            <div>
              <div>打开微信扫一扫</div>
              <div>立即添加客服微信</div>
            </div>
          </div>
          <!-- 服务保障 -->
          <div class="lx-list ">
            <div>服务保障</div>
            <div class="lx-bz">
              <div class="fwbz-list"
                   v-for="(item,index) in bzfuList"
                   :key="index">
                <div class="fwbz-img">
                  <img :src="item.imgUrl"
                       alt="">
                </div>
                <div class="fwbz-r">
                  <div>{{item.title}}</div>
                  <div>{{item.desc}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 热线电话 -->
          <div class="lx-list">
            <div>热线电话</div>
            <div class="lx-phone">
              <div>
                <img src="../assets/images/pc/phone.png"
                     alt="">
              </div>
              <div>0731-85118243</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      gsDescList: [
        { title: '企业概况：', desc: '长沙卓擎电子商务有限公司位于长沙市高新区，是一家专注于电商数字化转型的技术服务高新科技技术企业，为电商商家提供一站式全链路订单管理系统。' },
        { title: '公司规模：', desc: '作为一家业务前沿、创新性强的互联网产品型企业，我们已经服务了全国数万家的企业客户，辐射用户上亿。公司目前形成长沙、广州、杭州、北京四个地区的全国公司战略布局。目前公司在职员工近600人，其中研发人员200多人；可以根据客户需求提供量身定制的数字化解决方案，为客户提供全方位、多层次的服务支持，帮助客户在数字化转型过程中迅速占领市场。' },
        { title: '主营业务：', desc: '公司聚焦电商行业，向店群商家提供云服务管理及智能聊天平台等服务。我们将持续发扬追求极致，勇于创新的精神，用智慧赋能电商未来，与客户、伙伴一起，共创美好未来。' },
      ],
      bzfuList: [
        { imgUrl: require('../assets/images/md/footer1.png'), name: '7*14小时', desc: '在线客服解答' },
        { imgUrl: require('../assets/images/md/footer2.png'), name: '一对多', desc: 'VIP售后群服务' },
        { imgUrl: require('../assets/images/md/footer3.png'), name: '定期培训', desc: '专业团队上门指导' },
      ],
    }
  },
  created () {

  },
  methods: {
    toJinTun () {
      window.location.href = window.PLATFROM_CONFIG.JTWebUrl
    },
    LiJiTiyan () {
      this.$router.push('/download')
      this.setSessionItem('routerName', '/download')
    },
  }
}
</script>

<style lang="less" scoped>
</style>
